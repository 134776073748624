import { useEffect, useState } from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import { Box, BoxProps, useBreakpointValue, AspectRatio, useTheme } from '@chakra-ui/react'
import { ContentstackImage } from '@Types/contentstack'
import { BannerImage, BannerImageProps } from './banner-image'
import { BannerText, BannerTextProps } from './banner-text'
import { getScreenWidthWithBreakpointLimits } from './helpers'
import { BaseProps } from './types'
import { imageOptimization } from '../general/components/image-optimization'
export interface BannerFullProps extends BaseProps {
  placeholder_id?: string | null
  priority?: boolean
  image?: BannerImageProps
  text?: BannerTextProps
  textPosition?: BannerFullTextPosition
  contentPosition?: BannerFullContentPosition
  theme?: BannerFullTheme
  displayOpacity?: boolean
  customTitleColor?: string
  desktopContainerHeight?: string
  desktop_container_width?: string
  overlayImageDesktop?: ContentstackImage | null
  overlayImageMobile?: ContentstackImage | null
  overlayImageHeight?: string | null
  analyticsTrackingData?: string | null
  relativeIndex?: number | null
  contentVerticalSpacing?: number | null
  link1Padding?: string | null
  link2Padding?: string | null
  link3Padding?: string | null
  link4Padding?: string | null
  link5Padding?: string | null
  link6Padding?: string | null
  link1Margin?: string | null
  link2Margin?: string | null
  link3Margin?: string | null
  link4Margin?: string | null
  link5Margin?: string | null
  link6Margin?: string | null
}

type BannerFullTextPosition = 'left' | 'center' | 'right'
type BannerFullTheme = 'dark' | 'light'
type BannerFullContentPosition = 'Top' | 'Middle' | 'Bottom'

export const BannerFull = ({
  placeholder_id,
  priority = false,
  text,
  image,
  textPosition = 'center',
  theme = 'dark',
  displayOpacity,
  customTitleColor,
  desktopContainerHeight,
  desktop_container_width,
  contentPosition = 'Middle',
  overlayImageDesktop,
  overlayImageMobile,
  overlayImageHeight,
  analyticsTrackingData,
  isMobileDevice,
  contentVerticalSpacing,
}: BannerFullProps) => {
  const { font: titleFont } = text?.title ?? {}
  const textPositionProps = getTextPositionProps()

  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  const headingFontFamily = titleFont?.family ?? 'libre-franklin'
  const headingFontSize = titleFont?.size ?? '400'
  const overlayBackground = displayOpacity ? overlayBackgroundValue[theme] : undefined
  const verticalContentPositionValue = contentPositionValue[contentPosition]
  const mobileImage = image?.imageMobile
  const textPositionPropsBasedOnSize = isMobile ? { width: ['100%', '100%', '100%'] } : textPositionProps[textPosition]
  const [imageHeight, setImageHeight] = useState<number | null>(null)
  const [screenWidth, setScreenWidth] = useState<number>(0)

  const themeHook = useTheme()
  const breakpoints = themeHook.breakpoints

  const showBannerText =
    text?.title?.content?.children ||
    text?.eyebrow?.children ||
    text?.body?.children ||
    text?.ctaButtonPrimary?.children ||
    text?.ctaButtonSecondary?.children ||
    text?.ctaLinkItems?.length
      ? true
      : false

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      setScreenWidth(width)
    }
    setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const getImageDimensions = async (url: string) => {
      try {
        const img = document.createElement('img')

        const dimensions = await new Promise<{ width: number; height: number }>((resolve, reject) => {
          img.onload = () => {
            resolve({ width: img.width, height: img.height })
          }
          img.onerror = (error) => {
            reject(error)
          }
          img.src = url
        })

        if (dimensions.width && screenWidth) {
          const screenWidthLimited = getScreenWidthWithBreakpointLimits(
            breakpoints,
            desktop_container_width,
            screenWidth,
          )
          const scale = screenWidthLimited / dimensions.width
          setImageHeight(dimensions.height * scale)
        }
      } catch (error) {
        console.error('Error loading image:', error)
      }
    }

    if (image?.imageDesktop?.src) {
      getImageDimensions(image.imageDesktop.src)
    }
  }, [screenWidth])

  return (
    <Box
      id={placeholder_id}
      position="relative"
      display="flex"
      flexWrap="wrap"
      alignItems={verticalContentPositionValue}
      minHeight={isMobile ? 'auto' : imageHeight}
      gap={isMobile ? 4 : 0}
      data-promotion-name={analyticsTrackingData}
    >
      {isMobile ? (
        <AspectRatio ratio={1} width="100%" {...(image?.href && { as: NextLink, href: image?.href })}>
          <Image src={mobileImage?.src} alt={mobileImage?.alt} fill />
        </AspectRatio>
      ) : (
        <BannerImage
          href={imageOptimization(image?.href)}
          isMobileDevice={isMobileDevice}
          priority={priority}
          root={{
            ...(!image.href && { pointerEvents: 'none' }),
            ...image?.root,
            ...parentSizeProps,
          }}
          imageBox={{
            ...image?.imageBox,
            ...parentSizeProps,
          }}
          overlayBackground={overlayBackground}
          {...image}
        />
      )}
      {showBannerText && (
        <BannerText
          isMobileDevice={isMobileDevice}
          analyticsTrackingData={analyticsTrackingData}
          root={{
            p: isMobile ? 0 : 16,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: isMobile ? 'center' : null,
            ...text?.root,
            ...textPositionPropsBasedOnSize,
          }}
          eyebrow={{
            textStyle: ['eyebrow-75'],
            textColor: theme === 'dark' ? ['text'] : ['background'],
            ml: textPosition === 'center' ? 'auto' : undefined,
            mr: textPosition === 'center' ? 'auto' : undefined,
            ...text?.eyebrow,
          }}
          title={{
            font: {
              size: headingFontSize,
              family: headingFontFamily,
            },
            content: {
              textColor: setTitleColor(theme, customTitleColor),
              ...text?.title.content,
            },
          }}
          body={{
            textStyle: isMobile ? 'body-75' : 'body-200',
            textColor: theme === 'dark' ? 'text' : 'background',
            ...text?.body,
          }}
          ctaButtonBox={{
            justifyContent: textPosition === 'center' ? 'center' : undefined,
            ...text?.ctaButtonBox,
          }}
          ctaButtonPrimary={{
            ...text?.ctaButtonPrimary,
          }}
          ctaButtonSecondary={{
            ...text?.ctaButtonSecondary,
          }}
          ctaLinkBox={{
            justifyContent: textPosition === 'center' ? 'center' : undefined,
            ...text?.ctaButtonBox,
          }}
          ctaLinkItems={text?.ctaLinkItems?.map((el) => {
            return {
              ...el,
            }
          })}
          overlay_image_mobile={overlayImageMobile}
          overlay_image_desktop={overlayImageDesktop}
          overlay_image_height={overlayImageHeight}
          content_alignment={textPosition === 'center' ? 'center' : undefined} // Only send content_alignment prop if textPosition is center
          content_vertical_spacing={contentVerticalSpacing}
        />
      )}
    </Box>
  )
}

const parentSizeProps: BoxProps = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
}

const getTextPositionProps = (): Record<BannerFullTextPosition, BoxProps> => {
  return {
    left: {
      width: ['100%', '100%', '50%'],
    },
    center: {
      width: ['100%', '100%', '75%'],
      margin: '0 auto',
      textAlign: 'center',
    },
    right: {
      width: ['100%', '100%', '50%'],
      marginLeft: ['auto', 'auto', '50%'],
    },
  }
}

const setTitleColor = (theme: BannerFullTheme, customTitleColor: string | undefined) => {
  if (customTitleColor) {
    return customTitleColor
  } else {
    return theme === 'dark'
      ? ['text', null, 'text', null, 'text']
      : ['background', null, 'background', null, 'background']
  }
}

const overlayBackgroundValue: Record<BannerFullTheme, string> = {
  light: 'rgba(0, 0, 0, 0.5)',
  dark: 'rgba(255, 255, 255, 0.5)',
}

const contentPositionValue: Record<BannerFullContentPosition, string> = {
  Top: 'flex-start',
  Middle: 'center',
  Bottom: 'flex-end',
}
